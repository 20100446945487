import React from 'react';
import './Law.css';
import JudgmentImg from '../assets/judgement.jpg';
import DeepFakeImg from '../assets/deepfake.jpg';

function Law() {
  return (
    <div className="law-container">
      {/* 헤더 섹션 */}
      <header className="law-header">
        <h1>성폭력처벌법 개론</h1>
      </header>

      {/* 성폭력처벌법 개요 */}
      <section className="law-section">
        <h2>성폭력처벌법이란?</h2>
        <img src={JudgmentImg} alt="재판" className="Law-image" />
        <p>
          성폭력처벌법은 2010년도에 처음 제정된 법률로, 성폭력 범죄에 대한 처벌과 피해자 보호를 목적으로 하고 있습니다. 
          정식 명칭은 <strong>성폭력 범죄의 처벌 등에 관한 특례법</strong>입니다. 이 법은 성폭력 범죄의 정의부터 각 상황에 따른 처벌 규정을 명시하고 있으며, 
          피해자와 가해자 간의 법적 절차를 명확히 규정하고 있습니다.
        </p>
      </section>

      {/* 성폭력처벌법 주요 조항 */}
      <section className="law-section">
        <h2>성폭력처벌법 주요 조항</h2>
        <ul>
          <li><strong>제 2조: 성폭력범죄의 정의</strong></li>
          <li><strong>제 3 ~ 9조: 특이 상황에서의 강간 범죄</strong></li>
          <li><strong>제 10조: 업무상 위력에 의한 추행</strong> - 직장 내에서 상사 또는 동료가 권력이나 지위를 이용해 성적 추행을 저지르는 경우</li>
          <li><strong>제 11조: 공중밀집장소 추행 (공밀추)</strong> - 대중교통, 공연장 등 공공장소에서 타인의 의사에 반하여 성적 행위를 강요하는 경우</li>
          <li><strong>제 12조: 성적목적 다중이용장소 침입 (성목공)</strong> - 성적 목적을 가지고 다중이 이용하는 장소에 침입하는 행위</li>
          <li><strong>제 13조: 통신매체를 이용한 음란행위 (통매음)</strong> - 전화, 이메일, 메시지 등을 이용해 타인에게 음란한 내용을 전송하는 행위</li>
          <li><strong>제 14조: 카메라이용촬영죄 (카촬죄, 불법촬영물)</strong> - 동의 없이 타인의 신체를 촬영하거나 그 영상을 유포하는 행위 (7년 이하 징역, 5천만원 이하 벌금)</li>
          <li><strong>제 14조의 2: 허위영상물 반포 (딥페이크)</strong> - 허위 영상물을 제작 및 유포하는 행위 (5년 이하 징역, 5천만원 이하 벌금)</li>
          <li><strong>제 21조: 수사 및 재판에서의 피해자 보호 조치</strong> - 피해자 보호를 위한 수사 및 재판 과정에서의 다양한 조치들을 명시</li>
          <li><strong>제 42조: 신상정보 등록 및 공개</strong> - 성범죄자의 신상정보 등록 및 공개를 통해 재발 방지와 사회적 안전을 도모</li>
        </ul>
      </section>

      {/* 성폭력처벌법의 중요성 강조 */}
      <section className="law-section">
        <h2>성폭력처벌법의 중요성</h2>
        <p>
          성폭력처벌법은 피해자의 인권을 보호하고, 가해자에게 강력한 처벌을 가함으로써 성범죄를 예방하고자 하는 중요한 법률입니다. 
          특히, 디지털 성범죄와 같은 새로운 형태의 범죄에 대해서도 명확한 처벌 규정을 마련하여 성범죄의 위험성을 경고하고 있습니다.
        </p>
      </section>
      
      {/* 성폭력처벌법의 중요성 강조 */}
      <section className="law-section">
        <h2>딥페이크 처벌 법안</h2>
        <img src={DeepFakeImg} alt="딥페이크 법안 통과과" className="Law-image" />
        <p>(2024. 10. 16. 개정 성폭력처벌법 시행)</p>
        <p>
          성적 허위영상물의 소지·구입·저장·시청 행위도 징역 3년 또는 3000만 원 이하 벌금으로 처벌합니다.
          아울러, 성적 허위영상물 편집·반포 때 법정형을 불법 촬영물과 동일하게 5년에서 7년으로 상향하고, 반포 목적이 없어도 처벌합니다.
        </p>
      </section>

      <footer className="law-footer">
        <p>성폭력처벌법에 대해 공부하여 피해자를 보호하고, 성범죄의 재발을 방지하는 것이 중요합니다.</p>
      </footer>
    </div>
  );
}

export default Law;
