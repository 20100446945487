import React from 'react';
import './Footer.css';  // CSS 파일도 별도로 관리

function Footer() {
  return (
    <footer className="footer">
      {/* <p>도움이 되셨다면 그들을 위해 작은 나눔으로 도움을 주셨으면 합니다.</p>
      <button>
        <a href='https://www.sisters.or.kr/donate/guide' target="_blank" rel="noopener noreferrer">
          한국성폭력상담소 후원하기
        </a>
      </button>
      <button>
        <a href='https://crisis-center.or.kr/33' target="_blank" rel="noopener noreferrer">
          한국성폭력위기센터 후원하기
        </a>
      </button>
      <button>
        <a href='https://seoul1366.or.kr/bbs/board.php?bo_table=B17' target="_blank" rel="noopener noreferrer">
          여성긴급전화1366 후원하기
        </a>
      </button> */}
      <p>사이트의 내용 중 잘못된 점을 메일로 보내주시면 바로 수정하도록 하겠습니다.</p>
      <p>이 사이트는 공익을 위해 만든 사이트이며, 영리목적으로 사용되지 않음을 알립니다.</p>
      <p>2025.01.02 딥페이크 성범죄 방지 내용 추가하였습니다.</p>
      <p>created by 정한별 (jhb990438@gmail.com)</p>
    </footer>
  );
}

export default Footer;
